import React from 'react'
import { Grid } from '@mui/material'
import tg from './tgss.png'


import m1 from './Charlie/1.webp'
import meme from './Charlie/meme.png'
import m2 from './Charlie/2.webp'
import m3 from './Charlie/3.webp'
import m4 from './Charlie/4.webp'
import m5 from './Charlie/5.webp'
import m6 from './Charlie/6.webp'
import char from './char.png'
import dext from './dextools.svg'
import dexs from './dexs.png'





import { BsTwitterX } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";

function Desktop() {
  return (
    <div className=''>

<div className='bgcm'>

<div className='container'>
   
<Grid container>
          
          <Grid item md={12} xs={12} lg={12} sm={12} className='centerall'>

          <img src={char} style={{width:"100%"}}/>
          <br/>   <br/>  <br/>   <br/>   <br/>
          </Grid>  
  
          <Grid item md={12} xs={12} lg={12} sm={12} className='centerall'>
<a href='https://x.com/charliebit84707'><BsTwitterX style={{color:"#fff"}} className='fontx'/></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
<a href='https://t.me/CharlieCTOPortal'><FaTelegramPlane style={{color:"#fff"}} className='fontx'/></a>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
<a href=' https://www.dextools.io/app/en/solana/pair-explorer/7tWJMWEdYkJhKaDSKSdQ13hor8wk82uJo9Sw1hEfUbbT?t=1719101037266'><img src={dext} style={{width:"40px"}}/></a>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
<a href='https://dexscreener.com/solana/7tWJMWEdYkJhKaDSKSdQ13hor8wk82uJo9Sw1hEfUbbT'><img src={dexs} style={{width:"40px" , borderRadius:"10px"}}/></a>
          <br/>   <br/>  
          </Grid>  
      
          <Grid item md={12} xs={12} lg={12} sm={12} className='centerall'>
          <br/>   <br/>   
<h4>“OUCH CHARLIE BIT ME”</h4>


</Grid>  

<Grid item md={12} xs={12} lg={12} sm={12} className='centerall'>

<h5>CA: kyKfGjFCekidjPPxcwaosmAXEotM9j7oN6tVhiHpump</h5>
<br/>   <br/>    <br/>   <br/>   

</Grid>  
          
                  </Grid>
</div>

</div>

      <br/>



      <div className='container'>
      <Grid container>
          
          <Grid item md={4} xs={12} lg={4} sm={12} className='centerall'>

<img src={m1} style={{width:"100%"}}/>

          </Grid>
          <Grid item md={4} xs={12} lg={4} sm={12} className='centerall'>

<img src={meme} style={{width:"100%"}}/>

          </Grid>
          <Grid item md={4} xs={12} lg={4} sm={12} className='centerall'>

<img src={m2} style={{width:"100%"}}/>

          </Grid>



          <Grid item md={6} xs={12} lg={6} sm={12} className='centerall'>

<img src={m3} style={{width:"100%"}}/>

          </Grid>
          <Grid item md={6} xs={12} lg={6} sm={12} className='centerall'>

<img src={m4} style={{width:"100%"}}/>

          </Grid>
          <Grid item md={6} xs={12} lg={6} sm={12} className='centerall'>

<img src={m5} style={{width:"100%"}}/>

          </Grid>
          <Grid item md={6} xs={12} lg={6} sm={12} className='centerall'>

<img src={m6} style={{width:"100%"}}/>

          </Grid>
          
          </Grid>

      </div>
<br/>

          <Grid container>
          
           <Grid item md={12} xs={12} lg={12} sm={12} className='centerall'>

<img src={tg} style={{width:"100%"}}/>

           </Grid>
 
        
           </Grid>
 <br/>
        

    </div>
  )
}

export default Desktop